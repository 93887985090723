var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "messenger-card" }, [
    _c("div", { staticClass: "messenger-card__main" }, [
      _c("div", { staticClass: "fw500 flex bet a-center" }, [
        _vm.data.item.name
          ? _c("div", {
              style: { "margin-bottom": "10px", "margin-right": "15px" },
              domProps: { textContent: _vm._s(_vm.data.item.name) },
            })
          : _c("div", {
              style: { "margin-bottom": "10px", "margin-right": "15px" },
              domProps: {
                textContent: _vm._s(
                  _vm.data.first_name + " " + _vm.data.last_name
                ),
              },
            }),
      ]),
      _c(
        "div",
        {
          staticClass: "messenger-card__main_main",
          style: {
            display: "flex",
            "justify-content": "space-around",
            width: "100%",
            "margin-bottom": "10px",
          },
        },
        [
          _c("div", [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("hours")) },
            }),
            _vm.data.item.total_hours
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm
                        .moment("2015-01-01")
                        .startOf("day")
                        .seconds(
                          (_vm.data.item.total_hours < 24
                            ? _vm.data.item.total_hours
                            : _vm.data.item.total_hours - 24) * 3600
                        )
                        .format(
                          _vm.data.item.total_hours < 24 ? "HH:mm" : "D:HH:mm"
                        )
                    ),
                  },
                })
              : _c("div", { domProps: { textContent: _vm._s("-") } }),
          ]),
          _c("div", [
            _vm.data.item.unresolved_orders.length >= 0
              ? _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Orders")) },
                })
              : _vm._e(),
            _vm.data.item.unresolved_orders
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(_vm.data.item.unresolved_orders.length),
                  },
                })
              : _c("div", { domProps: { textContent: _vm._s("-") } }),
          ]),
          _c("div", { style: { display: "grid" } }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Moneybox")) },
            }),
            _vm.data.item.money_box >= 0 && _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s("₪" + _vm.data.item.money_box),
                  },
                })
              : _vm._e(),
            _vm.data.item.money_box < 0 && _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      "₪" + _vm.data.item.money_box * -1 + "-"
                    ),
                  },
                })
              : _vm._e(),
            _vm.data.item.money_box >= 0 && _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol + _vm.data.item.money_box
                    ),
                  },
                })
              : _vm._e(),
            _vm.data.item.money_box < 0 && _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol +
                        _vm.data.item.money_box * -1 +
                        "-"
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }